import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import phoneAccauntImage from 'source/components/illustration/assets/phone-accaunt.webp';
import {Routes} from 'source/utilities/network/url';
import DOMPurify from 'isomorphic-dompurify';
import {Container} from '../container';
import SectionTag from '../section-tag';
import {Text} from '../text';
import {Title} from '../title';
import ButtonLink from '../button-link';

const Wrapper = styled.div`
  padding: 70px 0 0;
  background: ${({theme}) => theme.palette.primaryBackground};
  box-shadow: ${({theme}) => theme.palette.insetShadowExtranet_2};

  @media (max-width: 450px) {
    padding-top: 46px;
  }
`;

const Box = styled(Container)`
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 48px;
`;

const TitleWrapper = styled.div`
  max-width: 475px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1023px) {
    margin-bottom: 46px;
    max-width: 300px;
  }

  @media (max-width: 640px) {
    max-width: 300px;
  }

  @media (max-width: 580px) {
    max-width: 290px;
  }

  @media (max-width: 480px) {
    margin-bottom: 0;
  }

  @media (max-width: 450px) {
    max-width: 61vw;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;

  @media (max-width: 480px) {
    margin-bottom: 70px;
    margin-top: 0;
  }

  @media (max-width: 470px) {
    margin-bottom: 46px;
  }
`;

const ImageWrapper = styled.div`
  margin-right: 65px;
  background: ${({theme}) => theme.palette.primaryBackground};
  width: 368px;

  @media (max-width: 1024px) {
    margin-right: 0;
    width: 320px;
  }

  @media (max-width: 1023px) {
    width: 240px;
    height: 243px;
    position: absolute;
    right: 32px;
    bottom: 0;
    overflow: hidden;
  }

  @media (max-width: 635px) {
    width: 140px;
    height: 243px;
    right: 0;
  }

  @media (max-width: 380px) {
    width: 110px;
    height: 190px;
  }
`;

const StyledImage = styled(Image)`
  display: block;
  object-fit: contain;
  object-position: bottom;
  height: fit-content;
  background: ${({theme}) => theme.palette.primaryBackground};

  @media (max-width: 760px) {
    object-fit: cover;
    object-position: left;
    width: auto;
    height: 100%;
  }
`;

const BR = styled.span`
  display: block;
`;

const Account = () => {
  const {t} = useTranslation('index');
  return (
    <Wrapper>
      <Box>
        <TitleWrapper>
          <SectionTag text={t('account.tag')} />
          <Title size="XL" type="h2">
            {t('account.title')}
            <BR />
            <span>{t('account.title_accent')}</span>
          </Title>
          <Text size="SM2">
            <span
              dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t('account.subtitle'))}}
            />
          </Text>
          <ButtonWrapper>
            <ButtonLink href={Routes.manage}>{t('account.button')}</ButtonLink>
          </ButtonWrapper>
        </TitleWrapper>
        <ImageWrapper>
          <StyledImage src={phoneAccauntImage} alt={t('account.alt')} />
        </ImageWrapper>
      </Box>
    </Wrapper>
  );
};

export default Account;
