import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {CONNECTION, CONNECTION_MOBILE} from 'slices/landing/lib/constants';
import {useContext} from 'react';
import {UtilitiesContext} from 'source/context/utilities';
import IconContainer from '../icon-container';
import SectionTag from '../section-tag';
import {Text} from '../text';
import {Title} from '../title';
import {Container} from '../container';

const Wrapper = styled.div`
  padding: 140px 0;
  background: ${({theme}) => theme.palette.defaultBackground};

  @media (max-width: 640px) {
    padding: 70px 0;
  }

  @media (max-width: 450px) {
    padding: 46px 0;
  }
`;

const Box = styled(Container)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 640px) {
    display: block;
  }

  @media (max-width: 520px) {
    gap: 32px;
  }
`;

const TitleWrapper = styled.div`
  max-width: 608px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 640px) {
    margin-bottom: 48px;
  }

  @media (max-width: 520px) {
    margin-bottom: 32px;
  }
`;

const AboutWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 240px);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 276px);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 520px) {
    gap: 16px;
  }

  @media (max-width: 460px) {
    gap: 16px;
  }
`;

const BR = styled.span`
  display: block;

  @media (max-width: 1023px) {
    display: inline-block;
    width: 4px;
  }

  @media (max-width: 480px) {
    width: unset;
    display: block;
  }
`;

const TitleBR = styled(BR)`
  @media (max-width: 630px) {
    display: block;
  }
`;

const Connection = () => {
  const {t} = useTranslation('index');
  const [utilities] = useContext(UtilitiesContext);
  return (
    <Wrapper>
      <Box>
        <TitleWrapper>
          <SectionTag text={t('connection.tag')} />
          <Title size="XL" type="h2">
            {t('connection.title')}
            <TitleBR />
            <span>{t('connection.title_accent')}</span>
          </Title>
          <Text size="SM2">
            {t('connection.subtitle_1')}
            <BR />
            {t('connection.subtitle_2')}
          </Text>
        </TitleWrapper>
        <AboutWrapper>
          {utilities.windowWidth > 450
            ? CONNECTION.map((item) => (
                <IconContainer
                  key={item.icon}
                  iconName={item.icon}
                  height={item.height}
                  width={item.width}
                  href={item.href}
                />
              ))
            : CONNECTION_MOBILE.map((item) => (
                <IconContainer
                  key={item.icon}
                  iconName={item.icon}
                  height={item.height}
                  width={item.width}
                  href={item.href}
                />
              ))}
        </AboutWrapper>
      </Box>
    </Wrapper>
  );
};

export default Connection;
