import styled from '@emotion/styled';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import React, {Fragment} from 'react';
import {FaqType} from 'slices/landing/lib/types';
import {Link} from 'source/components/link';
import {FAQ, ZHILIBYLI_SUPPORT} from 'slices/landing/lib/constants';
import ButtonLink from '../button-link';
import {Container} from '../container';
import SectionTag from '../section-tag';
import TabItem from '../tab-item';
import {Text} from '../text';
import {Title} from '../title';

const Wrapper = styled.div`
  padding: 140px 0;
  background: ${({theme}) => theme.palette.backgroundExtranet};

  @media (max-width: 640px) {
    padding: 70px 0;
  }

  @media (max-width: 470px) {
    padding: 46px 0;
  }
`;

const Box = styled(Container)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 480px) {
    gap: 32px;
  }
`;

const TitleWrapper = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 480px) {
    gap: 10px;
  }
`;

const InfoWrapper = styled.div`
  max-width: 504px;
  padding: 16px 32px;
  border-radius: 16px;
  background: ${({theme}) => theme.palette.defaultBackground};
  box-shadow: ${({theme}) => theme.palette.backgroundBoxShadow};

  @media (max-width: 1023px) {
    max-width: 100%;
  }

  @media (max-width: 480px) {
    padding: 5px 21px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;

  @media (max-width: 480px) {
    margin-top: 14px;
  }
  @media (max-width: 375px) {
    margin-top: 10px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({theme}) => theme.palette.extranetBorder};
`;

const StyledText = styled(Text)`
  max-width: 495px;

  @media (max-width: 1023px) {
    max-width: 440px;
  }
  @media (max-width: 450px) {
    max-width: 315px;
  }
`;

const BR = styled.br`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  color: ${({theme}) => theme.palette.fontPrimary};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  @media (max-width: 470px) {
    font-size: clamp(10px, 3.2vw, 14px);
    line-height: clamp(13px, 4vw, 19px);
  }
`;

const setComponents = (links: FaqType['links']) => {
  if (!links || links.length === 0) {
    return [];
  }

  return links.map((link, index) => (
    <StyledLink key={Number(index)} href={link} />
  ));
};

const Faq = () => {
  const {t} = useTranslation('');
  return (
    <Wrapper>
      <Box>
        <TitleWrapper>
          <SectionTag text={t('index:faq.tag')} />
          <Title size="XL" type="h2">
            {t('index:faq.title')}
            <br />
            <span>{t('index:faq.title_accent')}</span>
          </Title>
          <StyledText size="SM2">{t('index:faq.subtitle')}</StyledText>
          <ButtonWrapper>
            <ButtonLink href={ZHILIBYLI_SUPPORT}>
              {t('index:faq.button')}
            </ButtonLink>
          </ButtonWrapper>
        </TitleWrapper>
        <InfoWrapper>
          {FAQ.map((item, itemIndex) => (
            <Fragment key={item.title}>
              {itemIndex > 0 && <Line />}
              <TabItem title={t(item.title)}>
                {item.content.map((text, contentIndex) => (
                  <Fragment key={String(`${itemIndex}-${contentIndex}`)}>
                    {contentIndex > 0 && <BR />}
                    {item.links?.length ? (
                      <Trans
                        i18nKey={text}
                        components={setComponents(item.links)}
                      />
                    ) : (
                      t(text)
                    )}
                  </Fragment>
                ))}
              </TabItem>
            </Fragment>
          ))}
        </InfoWrapper>
      </Box>
    </Wrapper>
  );
};

export default Faq;
