import {css} from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {Icon, IconName} from 'source/components/icon';
import {AlignType} from 'slices/landing/lib/types';
import DOMPurify from 'isomorphic-dompurify';
import {theme} from 'source/utilities/global-style';
import {stylesText} from '../text';
import {Title} from '../title';

const Wrapper = styled.div<{
  isNoWrapp?: boolean;
  minWidth?: number;
  maxWidth?: number;
  align?: string;
}>`
  font-family: ${({theme}) => theme.fontFamily.MONTSERRAT};
  width: 100%;
  box-shadow: 0 12px 56px 0 ${({theme}) => theme.palette.backgroundBoxShadow};
  border: 1px solid ${({theme}) => theme.palette.extranetBorder};
  background: ${({theme}) => theme.palette.primaryBackground};
  padding: 16px;
  gap: 16px;
  border-radius: 16px;
  transition: box-shadow 0.7s;
  display: flex;
  flex-direction: column;

  ${({align}) =>
    align &&
    css`
      align-items: ${align};
    `}

  ${({isNoWrapp}) =>
    isNoWrapp &&
    css`
      flex-direction: row;
      justify-content: flex-start;
    `}

  ${({minWidth}) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}

  ${({maxWidth}) =>
    maxWidth &&
    css`
      min-width: ${maxWidth}px;
    `}
  
  &:hover {
    box-shadow: ${({theme}) => theme.palette.extranetHoverBoxShadow};
  }

  @media (max-width: 450px) {
    padding: 10px;
  }

  @media (max-width: 375px) {
    gap: 8px;
  }
`;

const IconWrapper = styled.div<{
  iconColor?: string;
  isNoWrapp?: boolean;
}>`
  width: 64px;
  height: 64px;
  padding: 16px;
  border-radius: 8px;
  background: ${({theme, iconColor}) =>
    iconColor || theme.palette.backgroundIconExtranet_1};

  @media (max-width: 470px) {
    margin-bottom: 0;
    width: 42px;
    height: 42px;
    padding: 10px;
    svg {
      width: 21px;
      height: 21px;
    }
  }

  @media (max-width: 375px) {
    margin-bottom: 0;
  }
`;
const TextWrapper = styled.div`
  p {
    margin-top: 4px;
    ${() => css`
      font-family: ${theme.fontFamily.MONTSERRAT};
      color: ${theme.palette.fontSecondaryExtranet};
      ${stylesText.XS2}
    `}
  }
`;

interface Props {
  icon: IconName;
  title: string;
  subtitle?: string;
  iconColor?: string;
  isNoWrapp?: boolean;
  minWidth?: number;
  maxWidth?: number;
  align?: AlignType;
}

const AdvantageItem: FC<Props> = ({
  icon,
  title,
  subtitle,
  iconColor,
  isNoWrapp = false,
  minWidth,
  maxWidth,
  align = 'flex-start',
}) => {
  const {t} = useTranslation('index');

  return (
    <Wrapper
      isNoWrapp={isNoWrapp}
      minWidth={minWidth}
      maxWidth={maxWidth}
      align={align}
    >
      <IconWrapper iconColor={iconColor} isNoWrapp={isNoWrapp}>
        <Icon name={icon} height={32} width={32} />
      </IconWrapper>
      <TextWrapper>
        <Title size="SM3" type="h3">
          {t(title)}
        </Title>
        {subtitle && (
          <p
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t(subtitle))}}
          />
        )}
      </TextWrapper>
    </Wrapper>
  );
};

export default AdvantageItem;
