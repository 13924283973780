import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {Routes} from 'source/utilities/network/url';
import {ABOUT_TARIFFS} from 'slices/landing/lib/constants';
import AdvantageItem from '../advantage-item';
import AnimationNotification from '../animation-notification';
import ButtonLink from '../button-link';
import {Container} from '../container';
import SectionTag from '../section-tag';
import {Text} from '../text';
import {Title} from '../title';

const Wrapper = styled.div`
  padding: 140px 0;
  background: ${({theme}) => theme.palette.backgroundExtranet};

  @media (max-width: 1440px) {
    padding: 70px 0;
  }

  @media (max-width: 1024px) {
    padding: 70px 0 70px;
  }

  @media (max-width: 920px) {
    padding: 70px 0;
  }

  @media (max-width: 450px) {
    padding: 46px 0;
  }

  @media (max-width: 320px) {
    padding: 40px 0;
  }
`;

const Box = styled(Container)`
  margin: 0 auto 75px;

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`;

const StyledText = styled(Text)`
  max-width: 440px;

  @media (max-width: 720px) {
    max-width: 320px;
  }
  @media (max-width: 400px) {
    max-width: 225px;
  }
  @media (max-width: 320px) {
    max-width: 200px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 1440px) {
    margin-bottom: -50px;
  }

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }

  @media (max-width: 790px) {
    gap: 0;
  }
`;

const TitleWrapper = styled.div`
  max-width: 660px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1440px) {
    max-width: 530px;
    margin-bottom: 40px;
  }

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }

  @media (max-width: 790px) {
    max-width: 300px;
  }

  @media (max-width: 450px) {
    max-width: 280px;
  }

  @media (max-width: 400px) {
    min-width: 245px;
  }

  @media (max-width: 375px) {
    min-width: 187px;
  }
`;

const AboutWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    margin-top: 48px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 450px) {
    gap: 8px;
    margin-top: 28px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }

  @media (max-width: 480px) {
    margin-top: 0;
  }

  a {
    min-width: 174px;
  }
`;

const BR = styled.span`
  display: block;
`;

const Tariffs = () => {
  const {t} = useTranslation('index');
  return (
    <Wrapper>
      <Box>
        <Info>
          <TitleWrapper>
            <SectionTag text={t('tariffs.tag')} />
            <Title size="XL" type="h2">
              {t('tariffs.title')}
              <BR />
              <span>{t('tariffs.title_accent')}</span>
            </Title>
            <StyledText size="SM2">{t('tariffs.subtitle')}</StyledText>
            <ButtonWrapper>
              <ButtonLink href={Routes.manage}>
                {t('tariffs.button')}
              </ButtonLink>
            </ButtonWrapper>
          </TitleWrapper>
          <AnimationNotification />
        </Info>
        <AboutWrapper>
          {ABOUT_TARIFFS.map((item) => (
            <AdvantageItem
              key={item.title}
              title={item.title}
              subtitle={item.subtitle}
              icon={item.icon}
              isNoWrapp
              align="center"
            />
          ))}
        </AboutWrapper>
      </Box>
    </Wrapper>
  );
};

export default Tariffs;
