import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {STATISTICS} from 'slices/landing/lib/constants';
import {useContext} from 'react';
import {UtilitiesContext} from 'source/context/utilities';
import AdvantageItem from '../advantage-item';
import SectionTag from '../section-tag';
import {Title} from '../title';
import {Container} from '../container';
import ScrollContainer from '../scroll-container';

const Wrapper = styled.div`
  padding-top: 140px;
  background: ${({theme}) => theme.palette.backgroundExtranet};

  @media (max-width: 640px) {
    padding: 70px 0 0;
  }

  @media (max-width: 450px) {
    padding: 46px 0 0;
  }
`;

const Box = styled(Container)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 640px) {
    max-width: 350px;
  }

  @media (max-width: 450px) {
    max-width: 325px;
  }
`;

const ScrollBox = styled.div`
  padding-bottom: 70px;
  background: ${({theme}) => theme.palette.backgroundExtranet};

  @media (max-width: 640px) {
    padding-bottom: 24px;
  }

  @media (max-width: 450px) {
    padding-bottom: 14px;
  }

  @media (max-width: 320px) {
    padding-bottom: 0;
  }
`;

const BR = styled.span`
  @media (max-width: 1023px) {
    display: block;
  }
`;

const Statistics = () => {
  const {t} = useTranslation('index');
  const [utilities] = useContext(UtilitiesContext);
  return (
    <>
      <Wrapper>
        <Box>
          <TitleWrapper>
            <SectionTag text={t('statistics.tag')} />
            <Title size="XL" type="h2">
              {t('statistics.title')}
              <BR>{t('statistics.title_accent')}</BR>
            </Title>
          </TitleWrapper>
        </Box>
      </Wrapper>
      <ScrollBox>
        <ScrollContainer>
          {STATISTICS.map((item) => (
            <AdvantageItem
              key={item.title}
              title={item.title}
              subtitle={item.subtitle}
              icon={item.icon}
              maxWidth={utilities.windowWidth < 376 ? 140 : undefined}
            />
          ))}
        </ScrollContainer>
      </ScrollBox>
    </>
  );
};

export default Statistics;
