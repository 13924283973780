import React, {FC, PropsWithChildren} from 'react';
import styled from '@emotion/styled';

const ScrollWrapper = styled.div`
  width: 100vw;
  overflow-x: auto;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const ScrollContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 267px);
  scroll-behavior: smooth;
  gap: 24px;
  width: max-content;
  padding: 35px 32px 70px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(4, 222px);
  }

  @media (max-width: 1023px) {
    margin-left: calc((100vw - 640px) / 2);
  }

  @media (max-width: 640px) {
    margin-left: 0;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(4, 220px);
    gap: 8px;
    padding: 32px 10px 46px;
  }

  @media (max-width: 375px) {
    grid-template-columns: repeat(4, 170px);
  }
  @media (max-width: 320px) {
    padding-bottom: 46px;
    grid-template-columns: repeat(4, 140px);
  }
`;

interface Props extends PropsWithChildren {}

const ScrollContainer: FC<Props> = ({children}) => {
  return (
    <ScrollWrapper>
      <ScrollContent>{children}</ScrollContent>
    </ScrollWrapper>
  );
};

export default ScrollContainer;
