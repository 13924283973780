import {FC, PropsWithChildren} from 'react';
import {css, jsx, Theme, useTheme} from '@emotion/react';

const styles = {
  SM2: css`
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    @media (max-width: 480px) {
      font-size: clamp(12px, 3.7vw, 18px);
      line-height: clamp(16px, 5.6vw, 25px);
    }
  `,
  SM3: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    @media (max-width: 470px) {
      font-size: clamp(13px, 4vw, 20px);
      line-height: clamp(18px, 5.5vw, 28px);
    }
  `,
  XL: css`
    font-size: 48px;
    font-weight: 500;
    line-height: 57px;
    @media (max-width: 1440px) {
      font-size: 32px;
      line-height: 38px;
    }
    @media (max-width: 470px) {
      font-size: clamp(21px, 6.6vw, 32px);
      line-height: clamp(25px, 8vw, 38px);
    }
  `,
  XXL: css`
    font-size: 56px;
    font-weight: 500;
    line-height: 67px;
    @media (max-width: 1440px) {
      font-size: 48px;
      line-height: 57px;
    }
    @media (max-width: 640px) {
      font-size: 32px;
      line-height: 38px;
    }
    @media (max-width: 470px) {
      font-size: clamp(21px, 6.6vw, 32px);
      line-height: clamp(25px, 8vw, 38px);
    }
  `,
};

type TitleSize = keyof typeof styles;
type AlignType = 'center' | 'right' | 'left';

const VariantTitleStyle = (
  theme: Theme,
  size: TitleSize,
  align?: AlignType,
) => css`
  font-family: ${theme.fontFamily.MONTSERRAT};
  text-align: ${align ?? 'left'};
  color: ${theme.palette.fontExtranet};
  ${styles[size]}

  span {
    color: ${theme.palette.primaryAccent};
  }
`;

interface Props extends PropsWithChildren {
  size: TitleSize;
  className?: string;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  align?: AlignType;
}

export const Title: FC<Props> = ({
  children,
  size = 'XL',
  className,
  type = 'h1',
  align,
}) => {
  const theme = useTheme();
  const css = VariantTitleStyle(theme, size, align);
  return jsx(type, {className, css}, children);
};
