import {FC, PropsWithChildren} from 'react';
import {css, jsx, Theme, useTheme} from '@emotion/react';

export const stylesText = {
  MD: css`
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
    @media (max-width: 1440px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 25px;
    }
    @media (max-width: 450px) {
      font-size: clamp(12px, 3.7vw, 18px);
      line-height: clamp(16px, 5vw, 25px);
    }
  `,
  XS2: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    @media (max-width: 470px) {
      font-size: clamp(10px, 3.2vw, 14px);
      line-height: clamp(13px, 4vw, 19px);
    }
  `,
  SM: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 67px;
    line-height: 22px;
    @media (max-width: 470px) {
      font-size: clamp(11px, 3.4vw, 16px);
      line-height: clamp(14px, 4.4vw, 22px);
    }
  `,
  SMBold: css`
    font-size: 16px;
    font-weight: 700;
    line-height: 67px;
    line-height: 22px;
  `,
  SM2: css`
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    @media (max-width: 1440px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (max-width: 470px) {
      font-size: clamp(11px, 3.4vw, 16px);
      line-height: clamp(14px, 4.4vw, 22px);
    }
  `,
  SM3: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  `,
};

type TextSize = keyof typeof stylesText;
type AlignType = 'center' | 'right' | 'left';

const VariantTextStyle = (
  theme: Theme,
  size: TextSize,
  align?: AlignType,
) => css`
  text-align: ${align ?? 'left'};
  font-family: ${theme.fontFamily.MONTSERRAT};
  color: ${theme.palette.fontSecondaryExtranet};
  ${stylesText[size]}
`;

interface Props extends PropsWithChildren {
  size: TextSize;
  className?: string;
  type?: 'p' | 'span';
  align?: AlignType;
}

export const Text: FC<Props> = ({
  children,
  size = 'XS2',
  className,
  type = 'p',
  align,
}) => {
  const theme = useTheme();
  const css = VariantTextStyle(theme, size, align);
  return jsx(type, {className, css}, children);
};
